@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
  }
}

@font-face {
  font-family: "AIQalamQuranurdu";
  src: url("./Components/fontStyles/Al\ Qalam\ Quran\ Majeed\ Web2_D\ 1.otf");
}

@font-face {
  font-family: "Arabic";
  src: url("./Components/fontStyles/Jameel\ Noori\ Nastaleeq\ 1.ttf");
}

@font-face {
  font-family: "English";
  src: url("./Components/fontStyles/Roboto\ Regular.ttf");
}

@font-face {
  font-family: "Quran";
  src: url("../src/Components/fontStyles/Al Qalam Quran Majeed Web2_D 1.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.ayat-text {
  font-family: "Quran";
  font-size: 20px;
  text-align: right;
}

@font-face {
  font-family: "Urdu";
  src: url("../src/Components/fontStyles/Jameel\ Noori\ Nastaleeq\ 1.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.urdu-text {
  font-family: "Urdu";
  font-size: 20px;
  text-align: right;
}
